import React from "react";
import {Provider} from "react-redux";
import {persistor, sidrStore} from "@redux/store";
import {PersistGate} from 'redux-persist/integration/react';

export const withRedux = (PageComponent: any, {ssr = true} = {}) => {
    // @ts-ignore
    const WithRedux = ({...props}) => {
        return (
            <Provider store={sidrStore}>
                <PersistGate loading={null} persistor={persistor}>
                    <PageComponent {...props} />
                </PersistGate>
            </Provider>
        );
    };


    if (ssr || PageComponent.getInitialProps) {
        WithRedux.getInitialProps = async (context: { reduxStore: any; }) => {
            // Get or Create the store with `undefined` as initialState
            // This allows you to set a custom default initialState

            // Provide the store to getInitialProps of pages
            context.reduxStore = sidrStore;

            // Run getInitialProps from HOCed PageComponent
            const pageProps =
                typeof PageComponent.getInitialProps === "function"
                    ? await PageComponent.getInitialProps(context)
                    : {};

            // Pass props to PageComponent
            return {
                ...pageProps,
                initialReduxState: sidrStore.getState()
            };
        };
    }
    return WithRedux;
};
