import * as React from "react";
import styles from '@styles/layouts/header/header.module.scss';
import {useState} from "react";
import Link from "next/link";
import {useDispatch, useSelector} from "react-redux";
import {setOpenCloseCart} from "@redux/cart/cart-open.slice";
import {withRedux} from "@redux/with-redux-store";
import {useRouter} from 'next/router'

const Header: React.FC = (props: any) => {

    /**
     * Navigation Router
     */
    const router = useRouter()

    /**
     * To manage cart data globally
     */
    const dispatch = useDispatch();

    // the variable is used to handle the menu state in the mobile view
    const [menuState, setMenuState] = useState(0);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    /**
     * Search text
     */
    const [search, setSearch] = useState('');

    // To show cart count
    // @ts-ignore
    const sidrCart = useSelector((state) => state.sidrCart);

    /**
     * Hide menu in mobile due to caching pages we are setting up the menu state
     * @param e
     */
    const hideMenu = (e: any) => {
        e.stopPropagation();
        setMenuState(0);
    }
    /***
     * Contain the code of the head and top header to show menu and dropdown menu
     * on hover we are showing the dropdown menu to navigate user to the particular screen
     * and in mobile view we are opening the sliding menu from left to right and showing the items and sub items.
     * the css for this section contain it separate file for reuse in all pages.
     */
    return (
        <>
            <header className={`${styles.header} ${props.isHasAnnouncement ? styles.header_announcement : ''}`}>
                <div className={'container'}>
                    <Link className={'logo'} href={'/'}><img
                                                             src='/assets/images/decorations/extra/sidr_logo_black.svg'
                                                             alt='SIDR' height={157.42} width={28.83}/></Link>
                    <nav className={'nav_left'}>
                        <ul className={'nav__links'}>
                            <li>
                                <Link href={'/'} className={'drop_down_item'}>products</Link>
                                <div className={'drop_down_product'}>
                                    <div className={'section_items'}>
                                        <div className={'list_sections'}>
                                            <div>
                                                <span className={'custom_box'}>featured</span>
                                                <ul>
                                                    {
                                                        props.Collections.map((collection: any, _: number) => {
                                                            return collection.products.map((honey: any, index: number) => {
                                                                return (honey.IsFeatured ?
                                                                    <li key={`featured-${collection.Link}-${index}`}><a
                                                                        href={`/products/detail/${collection.Link}/${honey.Shopify_Link}`}>{honey.Title.replaceAll(" honey", "")}</a>
                                                                    </li> : null)
                                                            })
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={'list_sections'}>
                                            <div>
                                                <span className={'custom_box'}><a
                                                    href={'/products/list/honey?filter_type=sidr_type'}>sidr honey</a></span>
                                                <ul>
                                                    {
                                                        props.Collections && props.Collections.length > 0 ?
                                                            props.Collections[0].products.map((honey: any, index: number) => {
                                                                return (honey.Filter_Type.indexOf('sidr_type') > -1 ?
                                                                    <li key={'sidr-honey-' + index}><a
                                                                        href={'/products/detail/honey/' + honey.Shopify_Link}>{honey.Title}</a>
                                                                    </li> : null)
                                                            }) : <></>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={'list_sections'}>
                                            <div>
                                                <span className={'custom_box'}><a
                                                    href={'/products/list/honey?filter_type=normal_type'}>regional honey</a></span>
                                                <ul>
                                                    {
                                                        props.Collections && props.Collections.length > 0 &&
                                                        props.Collections[0].products.map((honey: any, index: number) => {
                                                            return (honey.Filter_Type.indexOf('normal_type') > -1 ?
                                                                <li key={'other-honey-' + index}><a
                                                                    href={'/products/detail/honey/' + honey.Shopify_Link}>{honey.Title}</a>
                                                                </li> : null)
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={'list_sections'}>
                                            <div>
                                                <span className={'custom_box'}><a
                                                    href={'/products/list/honey-packet?filter_type=sidr_type'}>sidr honey minis</a></span>
                                                <ul>
                                                    {
                                                        props.Collections && props.Collections.length > 1 &&
                                                        props.Collections[1].products.map((honeyPacket: any, index: number) => {
                                                            return (honeyPacket.Filter_Type.indexOf('sidr_type') > -1 ?
                                                                <li key={'sidr-honeyPacket-' + index}><a
                                                                    href={'/products/detail/honey-packet/' + honeyPacket.Shopify_Link}>{honeyPacket.Title.replaceAll(" honey", "")}</a>
                                                                </li> : null)
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={'list_sections'}>
                                            <div>
                                                <span className={'custom_box'}><a
                                                    href={'/products/list/honey-packet?filter_type=normal_type'}>seasonal honey pack</a></span>
                                                <ul>
                                                    {
                                                        props.Collections && props.Collections.length > 1 &&
                                                        props.Collections[1].products.map((honeyPacket: any, index: number) => {
                                                            return (honeyPacket.Filter_Type.indexOf('normal_type') > -1 ?
                                                                <li key={'other-honeypacket-' + index}><a
                                                                    href={'/products/detail/honey-packet/' + honeyPacket.Shopify_Link}>{honeyPacket.Title.replaceAll(" honey", "")}</a>
                                                                </li> : null)
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <img loading={"lazy"} alt={'product dropdown'}
                                         src='/assets/images/decorations/extra/dropdown_menu_products.webp'/>
                                </div>
                            </li>
                            <li><Link href='/suppliers' shallow={true}>suppliers</Link></li>
                            <li><Link href='/buy-in-bulk' shallow={true}>buy in bulk</Link></li>
                        </ul>
                    </nav>
                    <nav className={`nav_right${menuState > 1 ? ' nav_right_menu_open' : ''}`}>
                        <ul className={'nav__links'} style={{opacity: isSearchOpen ? 0 : 1}}>
                            <li>
                                <Link href={'/build-your-own'} shallow={true} className={'drop_down_item'}>build your
                                    own</Link>
                                <div className={'dropdown_list'}>
                                    <div>
                                        <span className={'custom_box'}><Link href={'/build-your-own'}>custom box</Link></span>
                                        <ul>
                                            <li><a href={'/build-your-own/small-box'}>small box</a></li>
                                            <li><a href={'/build-your-own/large-box'}>large box</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <Link href={'/about-us'} className={'drop_down_item'} shallow={true}>about</Link>
                                <div className={'dropdown_list about'}>
                                    <div>
                                        <ul>
                                            <li><Link href={'/innovation'}>innovation</Link></li>
                                            <li><Link href={'/all-recipes'}>recipes</Link></li>
                                            <li><Link href={'/consumer-care'}>consumer care</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li><Link href='/impact' shallow={true}>impact</Link></li>
                        </ul>
                        <div className={`back_arrow ${menuState > 1 ? 'back_arrow_show' : ''}`} onClick={() => {
                            setMenuState(1)
                        }}>
                            <img src='/assets/images/decorations/extra/ic_up_arrow.svg' alt={'back'}/>
                        </div>
                        <div className={'divider'} style={{opacity: isSearchOpen ? 0 : 1}}></div>
                        {
                            sidrCart.entities.length == 0 ?
                                <div className={'search_box_cart'}>
                                    <div className={'search-box'}>
                                        <input className={`search_input ${isSearchOpen ? 'show' : ''}`} type={"text"}
                                               placeholder={'What are you looking for?'} value={search}
                                               onKeyPress={(e) => {
                                                   if (e.key === "Enter") {
                                                       router.push(`/search?text=${search}`).then(() => {
                                                           setIsSearchOpen(!isSearchOpen)
                                                       })
                                                   }
                                               }} onChange={e => {
                                            setSearch(e.currentTarget.value);
                                        }}/>
                                    </div>
                                    <img style={{height: isSearchOpen ? '16px' : '22px'}} width={22} height={22}
                                         src={isSearchOpen ? '/assets/images/decorations/extra/ic_close.svg' : '/assets/images/decorations/extra/ic_search_top.svg'}
                                         alt={'search'} onClick={() => {
                                        if (isSearchOpen) {
                                            if (window.location.pathname != '/') {
                                                router.push(`/`).then(() => {
                                                    setSearch('');
                                                });
                                            }
                                        }
                                        setIsSearchOpen(!isSearchOpen)

                                    }}/>
                                    <div className={`menu_icon ${menuState != 0 ? 'menu_icon_cross' : ''}`}
                                         onClick={() => {
                                             if (menuState == 0) {
                                                 setMenuState(1);
                                             } else {
                                                 setMenuState(0);
                                             }
                                         }}>
                                        <div className="bar1"></div>
                                        <div className="bar2"></div>
                                        <div className="bar3"></div>
                                    </div>
                                </div>
                                :
                                <div className={'search_box_cart cart'}>
                                    <div className={`menu_icon ${menuState != 0 ? 'menu_icon_cross' : ''}`}
                                         onClick={() => {
                                             if (menuState == 0) {
                                                 setMenuState(1);
                                             } else {
                                                 setMenuState(0);
                                             }
                                         }}>
                                        <div className="bar1"></div>
                                        <div className="bar2"></div>
                                        <div className="bar3"></div>
                                    </div>
                                    <img className={'item_cart'} onClick={() => {
                                        dispatch(setOpenCloseCart({isCartOpen: 1}))
                                    }} src='/assets/images/decorations/extra/shop_bag_icon.svg' alt={'cart'}/>
                                    <div className={'item_count'} onClick={() => {
                                        dispatch(setOpenCloseCart({isCartOpen: 1}))
                                    }}>
                                        <span>{sidrCart.entities.length}</span>
                                    </div>

                                </div>
                        }

                    </nav>
                </div>
            </header>
            <div
                className={`${styles.side_menu} ${props.isHasAnnouncement ? styles.side_menu_announcement : ''} ${menuState != 0 ? styles.show : ''}`}>
                <div className={'container'}>
                    <div className={'search'}>
                        <input type="search" name="search" placeholder="What are you looking for?" onChange={e => {
                            setSearch(e.currentTarget.value);
                        }}></input>
                        <button onClick={() => {
                            router.push(`/search?text=${search}`).then(() => {
                                setSearch('')
                                setMenuState(0);
                                setIsSearchOpen(false)
                            })
                        }}><img src="/assets/images/decorations/extra/ic_search_top.svg" height={18} width={18} alt="search" onClick={() => {
                            router.push(`/search?text=${search}`).then(() => {
                                setSearch('')
                                setMenuState(0);
                                setIsSearchOpen(false)
                            })
                        }}/></button>
                    </div>
                    <ul>
                        <li>
                            <div>
                                <Link href={'/'} onClick={hideMenu}>home</Link>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => {
                                setMenuState(2)
                            }}>
                                <span>products</span>
                                <img src='/assets/images/decorations/extra/ic_down_arrow.svg' height={15} width={15} alt={'next'}/>
                                <div className={`inner_list ${menuState == 2 ? 'inner_list_active' : ''}`}>
                                    <ul>
                                        <li>
                                            <span>Featured</span>
                                            <ul>
                                                {
                                                    props.Collections.map((collection: any, _: number) => {
                                                        return collection.products.map((honey: any, index: number) => {
                                                            return (honey.IsFeatured ?
                                                                <li key={`featured-${collection.Link}-${index}`}>
                                                                    <a href={`/products/detail/${collection.Link}/${honey.Shopify_Link}`}>
                                                                        <span>{honey.Title.replaceAll(" honey", "")}</span>
                                                                        <img
                                                                            src='/assets/images/decorations/extra/ic_down_arrow.svg' height={15} width={15}
                                                                            alt={'next'}/>
                                                                    </a>
                                                                </li> : null)
                                                        })
                                                    })
                                                }

                                            </ul>
                                        </li>
                                        <li>
                                            <a href={'/products/list/honey?filter_type=sidr_type'}>sidr honey</a>
                                            <ul>
                                                {
                                                    props.Collections && props.Collections.length > 0 &&
                                                    props.Collections[0].products.map((honey: any, index: number) => {
                                                        return (honey.Filter_Type.indexOf('sidr_type') > -1 ?
                                                            <li key={'sidr-mobile-honey-' + index}>
                                                                <a href={'/products/detail/honey/' + honey.Shopify_Link}>
                                                                    <span>{honey.Title}</span>
                                                                    <img
                                                                        src='/assets/images/decorations/extra/ic_down_arrow.svg' height={15} width={15}
                                                                        alt={'next'}/>
                                                                </a>
                                                            </li> : null)
                                                    })
                                                }
                                            </ul>
                                        </li>
                                        <li>
                                            <a href={'/products/list/honey?filter_type=normal_type'}>regional honey</a>
                                            <ul>
                                                {
                                                    props.Collections && props.Collections.length > 0 &&
                                                    props.Collections[0].products.map((honey: any, index: number) => {
                                                        return (honey.Filter_Type.indexOf('normal_type') > -1 ?
                                                            <li key={'other-mobile-honey-' + index}>
                                                                <a href={'/products/detail/honey/' + honey.Shopify_Link}>
                                                                    <span>{honey.Title}</span>
                                                                    <img
                                                                        src='/assets/images/decorations/extra/ic_down_arrow.svg' height={15} width={15}
                                                                        alt={'next'}/>
                                                                </a>
                                                            </li> : null)
                                                    })
                                                }
                                            </ul>
                                        </li>
                                        <li>
                                            <a href={'/products/list/honey-packet?filter_type=sidr_type'}>sidr
                                                honey minis</a>
                                            <ul>
                                                {
                                                    props.Collections && props.Collections.length > 1 &&
                                                    props.Collections[1].products.map((honeyPacket: any, index: number) => {
                                                        return (honeyPacket.Filter_Type.indexOf('sidr_type') > -1 ?
                                                            <li key={'sidr-mobile-honey-packet-' + index}>
                                                                <a href={'/products/detail/honey-packet/' + honeyPacket.Shopify_Link}>
                                                                    <span>{honeyPacket.Title.replaceAll(" honey", "")}</span>
                                                                    <img
                                                                        src='/assets/images/decorations/extra/ic_down_arrow.svg' height={15} width={15}
                                                                        alt={'next'}/>
                                                                </a>
                                                            </li> : null)
                                                    })
                                                }
                                            </ul>
                                        </li>
                                        <li>
                                            <a href={'/products/list/honey-packet?filter_type=normal_type'}>seasonal honey pack</a>
                                            <ul>
                                                {
                                                    props.Collections && props.Collections.length > 1 &&
                                                    props.Collections[1].products.map((honeyPacket: any, index: number) => {
                                                        return (honeyPacket.Filter_Type.indexOf('normal_type') > -1 ?
                                                            <li key={'other-mobile-honey-packet-' + index}>
                                                                <a href={'/products/detail/honey-packet/' + honeyPacket.Shopify_Link}>
                                                                    <span>{honeyPacket.Title.replaceAll(" honey", "")}</span>
                                                                    <img
                                                                        src='/assets/images/decorations/extra/ic_down_arrow.svg' height={15} width={15}
                                                                        alt={'next'}/>
                                                                </a>
                                                            </li> : null)
                                                    })
                                                }
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <Link href={'/suppliers'} onClick={hideMenu}>suppliers</Link>
                            </div>
                        </li>
                        <li>
                            <div>
                                <Link href={'/buy-in-bulk'} onClick={hideMenu}>buy in bulk</Link>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => {
                                setMenuState(3)
                            }}>
                                <span>build your own</span>
                                <img src='/assets/images/decorations/extra/ic_down_arrow.svg' height={15} width={15} alt={'next'}/>
                                <div className={`inner_list ${menuState == 3 ? 'inner_list_active' : ''}`}>
                                    <ul>
                                        <li>
                                            <Link href={'/build-your-own'}>custom box</Link>
                                            <ul>
                                                <li>
                                                    <a href={'/build-your-own/small-box'}>
                                                        <span>small box</span>
                                                        <img src='/assets/images/decorations/extra/ic_down_arrow.svg' height={15} width={15}
                                                             alt={'next'}/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={'/build-your-own/large-box'}>
                                                        <span>large box</span>
                                                        <img src='/assets/images/decorations/extra/ic_down_arrow.svg' height={15} width={15}
                                                             alt={'next'}/>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div onClick={(event) => {
                                setMenuState(4)
                            }}>
                                <span>about</span>
                                <img src='/assets/images/decorations/extra/ic_down_arrow.svg' height={15} width={15} alt={'next'}/>
                                <div className={`inner_list ${menuState == 4 ? 'inner_list_active' : ''}`}>
                                    <ul>
                                        <li>
                                            <Link href={'/about-us'} onClick={hideMenu}>about</Link>
                                            <ul>
                                                <li>
                                                    <Link href={'/innovation'} onClick={hideMenu}>
                                                        <span>innovation</span>
                                                        <img src='/assets/images/decorations/extra/ic_down_arrow.svg' height={15} width={15}
                                                             alt={'next'}/>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link href={'/all-recipes'} onClick={hideMenu}>
                                                        <span>recipes</span>
                                                        <img src='/assets/images/decorations/extra/ic_down_arrow.svg' height={15} width={15}
                                                             alt={'next'}/>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link href={'/consumer-care'} onClick={hideMenu}>
                                                        <span>consumer care</span>
                                                        <img src='/assets/images/decorations/extra/ic_down_arrow.svg' height={15} width={15}
                                                             alt={'next'}/>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <Link href={'/impact'} onClick={hideMenu}>impact</Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
export default withRedux(Header)
