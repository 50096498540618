import {
    addToCart,
    decrementQuantity,
    incrementQuantity,
    removeFromCart,
    setCheckoutResponse
} from "@redux/cart/cart.slice";
import {setOpenCloseCart} from "@redux/cart/cart-open.slice";
import {request as requestInstance} from '../../api/subscription';

export const addToCartWithQuantity = (product: any, sidrCart: any, dispatch: any, callback?: any) => {
    if (product.totalInventory > 0) {
        const itemExists: any = sidrCart.entities.find((item: any) => item.id === product.id);
        if (itemExists) {
            updateCartQuantity(product, sidrCart, true, dispatch, () => {
                callback()
            })
        } else {
            requestInstance.post('shopifycheckout', {
                action: 'ADD',
                product: product,
                checkout: (sidrCart.checkout ? JSON.parse(sidrCart.checkout) : null)
            }).then((response: any) => {
                if (response.status == 200) {
                    const checkoutData = response.data
                    const lineItem = checkoutData.lineItems.find((item: any) => item.variant.id === product.variants[0].id);
                    product = {...product, lineItem};
                    dispatch(addToCart({...product}))
                    dispatch(setCheckoutResponse(JSON.stringify(checkoutData)))
                    dispatch(setOpenCloseCart({isCartOpen: 1}))
                }
            }).catch((error: any) => {
                console.log('Error', error)
            })

        }
    }
}

export const addCustomBoxToCart = (products: any, sidrCart: any, dispatch: any, callback?: any) => {

    requestInstance.post('shopifycheckout', {
        action: 'ADD',
        product: products,
        checkout: (sidrCart.checkout ? JSON.parse(sidrCart.checkout) : null)
    }).then((response: any) => {
        if (response.status == 200) {
            const checkoutData = response.data;
            for (let product of products.products) {
                const lineItem = checkoutData.lineItems.find((item: any) => item.variant.id === product.variants[0].id);
                product = {...product, lineItem};
                dispatch(addToCart({...product}))
            }
            dispatch(setCheckoutResponse(JSON.stringify(checkoutData)))
            dispatch(setOpenCloseCart({isCartOpen: 1}))
            if (callback)
                callback()
        }
    }).catch((error: any) => {
        console.log('Error', error)
    })
}
export const updateCartQuantity = (product: any, sidrCart: any, isIncress = true, dispatch: any, callback?: any) => {
    if (product.totalInventory > 0) {
        const itemExists: any = sidrCart.entities.find((item: any) => item.id === product.id);
        if (itemExists) {
            requestInstance.post('shopifycheckout', {
                action: 'UPDATE',
                product: {
                    ...itemExists,
                    quantity: (isIncress ? itemExists.quantity + 1 : itemExists.quantity - 1),
                },
                checkout: (sidrCart.checkout ? JSON.parse(sidrCart.checkout) : null)
            }).then((response: any) => {
                if (response.status == 200) {
                    const checkoutData = response.data;
                    callback(isIncress)
                    if (isIncress) {
                        dispatch(incrementQuantity(product.id))
                    } else {
                        dispatch(decrementQuantity(product.id))
                    }
                    dispatch(setCheckoutResponse(JSON.stringify(checkoutData)))
                }
            }).catch((error: any) => {
                console.log('Error', error)
            })
        } else {
            callback(isIncress)
        }
    }
}

export const removeItemFromCart = (product: any, sidrCart: any, dispatch: any) => {
    const itemExists: any = sidrCart.entities.find((item: any) => item.id === product.id);
    if (itemExists) {
        requestInstance.post('shopifycheckout', {
            action: 'REMOVE',
            product: itemExists,
            checkout: (sidrCart.checkout ? JSON.parse(sidrCart.checkout) : null)
        }).then((response: any) => {
            if (response.status == 200) {
                const checkoutData = response.data;
                dispatch(removeFromCart(itemExists.id))
                dispatch(setCheckoutResponse(JSON.stringify(checkoutData)))
                if (checkoutData.lineItems.length == 0) {
                    dispatch(setOpenCloseCart({isCartOpen: 0}))
                }
            }
        }).catch((error: any) => {
            console.log('Error', error)
        })

    }
}
export const removeCustomBoxItemFromCart = (product: any, sidrCart: any, dispatch: any) => {
    requestInstance.post('shopifycheckout', {
        action: 'REMOVE',
        product: product,
        checkout: (sidrCart.checkout ? JSON.parse(sidrCart.checkout) : null)
    }).then((response: any) => {
        if (response.status == 200) {
            const checkoutData = response.data;
            for (const productElement of product.products) {
                dispatch(removeFromCart(productElement.id))
            }
            dispatch(setCheckoutResponse(JSON.stringify(checkoutData)))
            if (checkoutData.lineItems.length == 0) {
                dispatch(setOpenCloseCart({isCartOpen: 0}))
            }
        }
    }).catch((error: any) => {
        console.log('Error', error)
    })
}

export const addDiscount = (discountCode: any, sidrCart: any, dispatch: any, callback?: any) => {
    requestInstance.post('shopifycheckout', {
        action: 'DISCOUNT',
        discountCode,
        checkout: (sidrCart.checkout ? JSON.parse(sidrCart.checkout) : null)
    }).then((response: any) => {
        if (response.status == 200) {
            const checkoutData = response.data;
            const discountError = checkoutData.userErrors.find((userError: any) => userError.field[0].value === 'discountCode');
            if (discountError) {
                callback(false, discountError.message)
            } else {
                dispatch(setCheckoutResponse(JSON.stringify(checkoutData)))
                if (callback) {
                    callback(true, discountCode)
                }
            }
        }
    }).catch((error: any) => {
        console.log('Error', error)
        if (callback) {
            callback(false, "Discount code Unable to find a valid discount matching the code entered")
        }
    })
}

export const removeDiscount = (sidrCart: any, dispatch: any, callback?: any) => {
    requestInstance.post('shopifycheckout', {
        action: 'REMOVEDISCOUNT',
        checkout: (sidrCart.checkout ? JSON.parse(sidrCart.checkout) : null)
    }).then((response: any) => {
        if (response.status == 200) {
            const checkoutData = response.data;
            dispatch(setCheckoutResponse(JSON.stringify(checkoutData)))
            if (callback) {
                callback(true, 'Success')
            }
        }
    }).catch((error: any) => {
        console.log('Error', error)
        if (callback) {
            callback(false, "")
        }
    })
}
