import {createSlice} from '@reduxjs/toolkit';

const cartSlice = createSlice({
    name: 'sidr-cart-open',
    initialState: {
        isCartOpen: 0
    },
    reducers: {
        setOpenCloseCart: (state, action) => {
            const body = document.getElementsByTagName('body');
            if(body.length > 0){
                if(action.payload.isCartOpen){
                    body[0].classList.add('hide-scroll')
                } else {
                    body[0].classList.remove('hide-scroll')
                }
            }
            state.isCartOpen = action.payload.isCartOpen;
        }
    },
});

export const carrOpenCloseReducer = cartSlice.reducer;

export const {
    setOpenCloseCart
} = cartSlice.actions;
