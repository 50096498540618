// Button.tsx
import React, {MouseEvent} from "react";
import styles from "@styles/components/button/index.module.scss";

export interface ButtonProps {
    children?: React.ReactNode;
    props?: any;
    classes?: string;
    disabled?: boolean | undefined
    onClick?: | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
}

const Button: React.FC<ButtonProps> = ({
                                           children, onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    }, ...props
                                       }) => {
    const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
        // @ts-ignore
        onClick(e);
    };
    return (
        <button className={`${styles.button} ${props.classes ? props.classes : ''}`} disabled={props.disabled}
                onClick={handleOnClick} {...props}>
            {children}
        </button>
    );
};
export default Button;
