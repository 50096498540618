import styles from "@styles/components/checkout-dialog/index.module.scss";
import Button from "@components/button/Button";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {setOpenCloseCart} from "@redux/cart/cart-open.slice";
import {
    addDiscount,
    removeCustomBoxItemFromCart,
    removeDiscount,
    removeItemFromCart,
    updateCartQuantity
} from "@redux/action/cartActions";
import {withRedux} from "@redux/with-redux-store";

const CheckoutDialog = () => {

    /**
     * To manage cart data globally
     */
    const dispatch = useDispatch();

    // Cart List
    const sidrCart = useSelector((state: any) => state.sidrCart);
    const [cartList, setCartList]: any = useState([]);

    useEffect(() => {

        if (sidrCart.checkout) {
            const checkOutCart = JSON.parse(sidrCart.checkout);
            if (checkOutCart.discountApplications && checkOutCart.discountApplications.length > 0) {
                setDiscountCode(checkOutCart.discountApplications[0].code)
            }
        }
        let newCartList = [];
        for (const product of sidrCart.entities) {
            if (product.lineItem.customAttributes.length > 0) {

                const uniqueKeyAttributes = product.lineItem.customAttributes.filter((customAttribute: any) => {
                    return customAttribute.key == 'uniqueid'
                });
                let uniqueKey: any = null;
                if (uniqueKeyAttributes.length > 0) {
                    uniqueKey = uniqueKeyAttributes[0].value
                }
                if (uniqueKey) {
                    let isExist = newCartList.filter((item: any) => {
                        return item.id == uniqueKey
                    });
                    if (isExist.length == 0) {
                        let boxProducts: any = [];
                        for (const productSearch of sidrCart.entities) {
                            if (productSearch.lineItem.customAttributes && productSearch.lineItem.customAttributes.length > 0) {
                                const isMatchProduct = productSearch.lineItem.customAttributes.filter((customAttribute: any) => {
                                    return (product.id != productSearch.id && customAttribute.value == uniqueKey)
                                });
                                if (isMatchProduct.length > 0) {
                                    boxProducts.push(productSearch)
                                }
                            }
                        }

                        if (boxProducts.length > 0) {
                            let customBox = {
                                id: uniqueKey,
                                isCustomBox: true,
                                images: [
                                    {
                                        originalSrc: `/assets/images/gift_box/${(1 + boxProducts.length) == 7 ? 'small_box/open_angle_gift_box_small.webp' : 'large_box/open_angle_gift_box_large.webp'}`,
                                        altText: `${(1 + boxProducts.length) == 2 ? 'small box' : 'large box'}`
                                    }
                                ],
                                title: `${(1 + boxProducts.length) == 7 ? 'small' : 'large'} box`,
                                products: [product, ...boxProducts]
                            }
                            newCartList.push(customBox);
                        }
                    }
                }
            } else {
                newCartList.push(product);
            }
        }
        // @ts-ignore
        setCartList(newCartList);
    }, [sidrCart])

    const isCartOpen = useSelector((state: any) => state.cartOpenClose);

    // Quantity related declaration
    const increaseQuantity = (index: number) => {
        const product = cartList[index];
        if (product.quantity < product.totalInventory) {
            updateCartQuantity({...product, quantity: product.quantity}, sidrCart, true, dispatch, () => {
            })
        }
    }
    const decreaseQuantity = (index: number) => {
        let minimumQuantity = 1;
        const product = cartList[index];
        if (product.tags.indexOf('Honey Packet') > -1) {
            minimumQuantity = 7;
        }
        if (product.quantity > minimumQuantity) {
            updateCartQuantity({...product, quantity: product.quantity}, sidrCart, false, dispatch, () => {
            })
        }
    }
    const removeProductFromCart = (index: number) => {
        const product = cartList[index];
        if (product.isCustomBox) {
            removeCustomBoxItemFromCart(product, sidrCart, dispatch)
        } else {
            removeItemFromCart(product, sidrCart, dispatch);
        }
    }

    const checkOutCart = () => {
        if (sidrCart.checkout) {
            const checkout = JSON.parse(sidrCart.checkout);
            window.location.href = checkout.webUrl;
        }
    }

    /**
     * email is used for store the value of the email input.
     */
    const [discountCode, setDiscountCode] = useState('');

    const applyDiscount = () => {
        if (discountCode != '') {
            addDiscount(discountCode, sidrCart, dispatch, (success: boolean, errorMessage: string) => {
            })
        }

    }

    const removeDiscountCode = () => {
        if (discountCode != '') {
            removeDiscount(sidrCart, dispatch, (success: boolean, errorMessage: string) => {
                setDiscountCode('')
            })
        }
    }
    return (
        <>
            <div className={`${styles.overlay_checkout} ${isCartOpen.isCartOpen ? styles.overlay_checkout_show : ''}`}
                 onClick={() => {
                     dispatch(setOpenCloseCart({isCartOpen: 0}))
                 }}></div>
            <div className={`${styles.checkout_dialog} ${isCartOpen.isCartOpen ? styles.checkout_dialog_show : ''}`}>
                <div className={'header'}>
                    <span>your cart</span>
                    <div className={'close_button'}>
                        <img src={'/assets/images/decorations/extra/ic_close.svg'} height={11.67} width={11.67} onClick={() => {
                            dispatch(setOpenCloseCart({isCartOpen: 0}))
                        }} alt={'close'}/>
                    </div>
                </div>
                <div className={'cart_list_items'}>
                    {
                        cartList.map((product: any, index: number) => {
                                return (<div key={'cart-item-' + index} className={`item`}>
                                    <img src={product.images[0].originalSrc} alt={product.images[0].altText}/>
                                    <div className={`detail ${product.isCustomBox ? 'center' : ''}`}>
                                        <span>{product.title}</span>
                                        {
                                            !product.isCustomBox &&
                                            <>
                                                <p>{product.metafields.net_wet}</p>
                                                <div className={'quantity_manage'}>
                                                    <div className={'quantity_adjust'}>
                                                        <button className={'button_minus'}
                                                                disabled={product.totalInventory == 0 || product.quantity == (product.tags.indexOf('Honey Packet') > -1 ? 7 : 1)}
                                                                onClick={() => {
                                                                    decreaseQuantity(index)
                                                                }}>-
                                                        </button>
                                                        <p>{product.quantity.toString()}</p>
                                                        <button
                                                            disabled={product.totalInventory == 0}
                                                            onClick={() => {
                                                                increaseQuantity(index)
                                                            }}>+
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className={`delete_price ${product.isCustomBox ? 'center' : ''}`}>
                                        <img src={'/assets/images/decorations/extra/shopify_theme_trash_box_icon.webp'} alt={'remove'}
                                             onClick={() => {
                                                 removeProductFromCart(index)
                                             }}/>
                                        {
                                            !product.isCustomBox &&
                                            <span>${(product.variants[0].price.amount * product.quantity).toFixed(2)}</span>
                                        }
                                    </div>
                                </div>)
                            }
                        )
                    }
                </div>
                <hr/>
                <div className={'discount_container'}>
                    <div className={'input_box'}>
                        <input placeholder={'Discount Code'} value={discountCode} onChange={(e: any) => {
                            setDiscountCode(e.currentTarget.value);
                        }}/>
                        {
                            discountCode != '' &&
                            <img src={'/assets/images/decorations/extra/ic_close.svg'} onClick={() => {
                                removeDiscountCode()
                            }} alt={'close'}/>
                        }
                    </div>

                    <Button classes={'discount_apply'} onClick={applyDiscount}>Apply</Button>
                </div>
                <div className={'sub_total_container'}>
                    <span
                        className={'sub_total_title'}>Subtotal ({sidrCart.entities.length} {sidrCart.entities.length == 1 ? 'item' : 'items'})</span>
                    <span className={'sub_total_value'}>${
                        sidrCart.checkout ? JSON.parse(sidrCart.checkout).totalPrice.amount : 0
                    }</span>
                </div>
                <Button classes={'checkout'} onClick={checkOutCart}>
                    <img src={'/assets/images/decorations/extra/checkout_lock_icon.webp'}  width={9} height={11} alt={'checkout'}/>
                    checkout
                </Button>
            </div>
        </>
    )
}
export default withRedux(CheckoutDialog)
