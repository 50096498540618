import * as React from "react";
import styles from '@styles/components/announcement/index.module.scss';
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, EffectFade, Navigation, Pagination} from "swiper";

// Import Swiper styles
import 'swiper/css';
// @ts-ignore
const Announcement = ({announcementList}) => {

    const swiperConfig = {
        modules: [Navigation, Pagination, EffectFade, Autoplay],
        spaceBetween: 0,
        centeredSlides: true,
        centeredSlidesBounds: true,
        initialSlide: 0,
        loop: true,
        fade: "true",
        speed: 2000,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 0,
            },
            481: {
                slidesPerView: 1,
                spaceBetween: 0,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 0,
            },
            1025: {
                slidesPerView: 1,
                spaceBetween: 0,
            },
            1201: {
                slidesPerView: 1,
                spaceBetween: 0,
            }
        }
    }

    /***
     * Contain the code of the announcement section with auto swipe
     */
    return (
        <>
            <div className={styles.header}>
                <div className={'announcement'}>
                    <Swiper {...swiperConfig}>
                        {
                            announcementList.map((announcement: any, index: number) => {
                                return (<SwiperSlide key={'announcement-' + index}>
                                    <div dangerouslySetInnerHTML={{__html: announcement.attributes.Title}}></div>
                                </SwiperSlide>)
                            })
                        }
                        <div className={`swiper-button-prev`} tabIndex={0} role="button"
                             aria-label="Previous slide">
                            <img src="/assets/images/decorations/extra/left_arrow.png" width={7} height={12} alt="previous"/>
                        </div>
                        <div className={`swiper-button-next`} tabIndex={0} role="button"
                             aria-label="Next slide">
                            <img src="/assets/images/decorations/extra/right_arrow.png" width={7} height={12} alt="next"/>
                        </div>
                        <div className={'swiper-pagination'}></div>
                    </Swiper>
                </div>
            </div>
        </>
    )
}
export default Announcement;
