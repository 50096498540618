import {createSlice} from '@reduxjs/toolkit';

export interface UserCart {
    checkout?: string;
    currentDiscount?: string
    entities: any[];
    loading: boolean
}

const initialUsersState: UserCart = {
    entities: [],
    loading: false
};

const cartSlice = createSlice({
    name: 'sidr-cart',
    initialState: initialUsersState,
    reducers: {
        resetUserState: (state: UserCart, action) => {
            state.checkout = undefined
            state.entities = []
        },
        setCheckoutResponse: (state: UserCart, action) => {
            state.checkout = action.payload;
        },
        addToCart: (state: UserCart, action) => {
            const itemExists: any = state.entities.find((item: any) => item.id === action.payload.id);
            if (itemExists) {
                if (itemExists.quantity < 5) {
                    itemExists.quantity++;
                }
            } else {
                state.entities.push({...action.payload});
            }
        },
        incrementQuantity: (state: UserCart, action) => {
            const item = state.entities.find((item: any) => item.id === action.payload);
            if (item) {
                item.quantity++;
            }
        },
        decrementQuantity: (state: UserCart, action) => {
            const item: any = state.entities.find((item: any) => item.id === action.payload);
            if (item) {
                if (item.quantity === 1) {
                    const index = state.entities.findIndex((item: any) => item.id === action.payload);
                    state.entities.splice(index, 1);
                } else {
                    item.quantity--;
                }
            }
        },
        removeFromCart: (state: UserCart, action) => {
            const index = state.entities.findIndex((item: any) => item.id === action.payload);
            if (index > -1)
                state.entities.splice(index, 1);
        },
    },
});

export const cartReducer = cartSlice.reducer;

export const {
    addToCart,
    incrementQuantity,
    decrementQuantity,
    removeFromCart,
    setCheckoutResponse,
    resetUserState
} = cartSlice.actions;
