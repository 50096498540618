import type {AppProps} from 'next/app'
import '../styles/styles.scss';
import * as React from "react";
import Head from "next/head";
import CheckoutDialog from "@components/checkout-dialog";
import {strapi} from "@api/Strapi";
import {useEffect} from "react";
import Header from "@components/layouts/header";
import {Footer} from "@components/layouts/footer";
import Script from "next/script";
import Announcement from "@components/announcement";

let navigationPropsCache: null = null;

// @ts-ignore
function MyApp({Component, pageProps, navigationProps}: AppProps) {

    useEffect(() => {
        navigationPropsCache = navigationProps;
    }, [])
    return (<>
        <Head>
            {
                pageProps && pageProps.seoDetails && pageProps.seoDetails.title ?
                    <>
                        <title>{pageProps.seoDetails.title ?? ''}</title>
                        <meta property="og:title"
                              content={pageProps.seoDetails.title ?? ''}/>
                        <meta name="twitter:title"
                              content={pageProps.seoDetails.title ?? ''}/>
                    </> :
                    navigationProps && navigationProps.dynamicSEO.Title ?
                        <>
                            <title>{navigationProps.dynamicSEO.Title ?? ''}</title>
                            <meta property="og:title"
                                  content={navigationProps.dynamicSEO.Title ?? ''}/>
                            <meta name="twitter:title"
                                  content={navigationProps.dynamicSEO.Title ?? ''}/>
                        </> :
                        <>
                            <title>SIDR@ Official Site | 100% Pure Raw & Unfiltered Honey</title>
                            <meta property="og:title" content=""/>
                            <meta name="twitter:title" content=""/>
                        </>
            }
            {
                pageProps && pageProps.seoDetails && pageProps.seoDetails.keyword ?
                    <meta name="keywords"
                          content={pageProps.seoDetails.keyword}/> :
                    navigationProps && navigationProps.dynamicSEO.Keyword ?
                        <meta name="keywords"
                              content={navigationProps.dynamicSEO.Keyword}/>
                        : <meta name="keywords"
                                content=""/>
            }
            {
                pageProps && pageProps.seoDetails && pageProps.seoDetails.description ?
                    <>
                        <meta name="description" content={pageProps.seoDetails.description}/>
                        <meta property="og:description" content={pageProps.seoDetails.description}/>
                        <meta name="twitter:description"
                              content={pageProps.seoDetails?.description}/>
                    </> :
                    navigationProps && navigationProps.dynamicSEO.Description ?
                        <>
                            <meta name="description"
                                  content={navigationProps.dynamicSEO.Description}/>
                            <meta property="og:description"
                                  content={navigationProps.dynamicSEO.Description}/>
                            <meta name="twitter:description"
                                  content={navigationProps.dynamicSEO.Description}/>
                        </>
                        :
                        <>
                            <meta name="description"
                                  content=""/>
                            <meta property="og:description"
                                  content=""/>
                            <meta name="twitter:description"
                                  content=""/>
                        </>
            }
            {
                pageProps && pageProps.seoDetails && pageProps.seoDetails.image ?
                    <>
                        <meta name="twitter:image" content={pageProps.seoDetails.image}/>
                        <meta property="twitter:image:alt"
                              content={pageProps.seoDetails.imageAlt ?? ""}/>
                        <meta property="og:image" content={pageProps.seoDetails.image}/>
                        <meta property="og:image:secure_url" content={pageProps.seoDetails.image}/>
                        <meta property="og:image:type" content="image/webp"/>
                        <meta property="og:image:alt"
                              content={pageProps.seoDetails.imageAlt ?? ""}/>
                    </> : <>
                        <meta name="twitter:image"
                              content="https://sidr.com/assets/images/decorations/extra/sidr_logo.webp"/>
                        <meta property="twitter:image:alt" content="SIDR@ Official Site | 100% Pure Raw & Unfiltered Honey"/>
                        <meta property="og:image"
                              content="https://sidr.com/assets/images/decorations/extra/sidr_logo.webp"/>
                        <meta property="og:image:secure_url"
                              content="https://sidr.com/assets/images/decorations/extra/sidr_logo.webp"/>
                        <meta property="og:image:type" content="image/webp"/>
                        <meta property="og:image:alt" content="SIDR@ Official Site | 100% Pure Raw & Unfiltered Honey"/>
                    </>
            }
            {
                pageProps && pageProps.seoDetails && pageProps.seoDetails.cardType ?
                    <meta name="twitter:card" content={pageProps.seoDetails.cardType}/> :
                    <meta name="twitter:card" content="summary_large_image"/>
            }
            {
                pageProps && pageProps.seoDetails && pageProps.seoDetails.url ?
                    <>
                        <meta property="og:url" content={`https://sidr.com${pageProps.seoDetails.url}`}/>
                        <link rel="canonical"
                              href={`https://sidr.com${pageProps.seoDetails.url.includes("?") ? pageProps.seoDetails.url.split('?')[0] : pageProps.seoDetails.url}`}
                              key="canonical" hrefLang={'en-us'}></link>

                        <link rel="alternate" hrefLang="x-default"
                              href={`https://sidr.com${pageProps.seoDetails.url.includes("?") ? pageProps.seoDetails.url.split('?')[0] : pageProps.seoDetails.url}`}></link>
                        <link rel="alternate" hrefLang="en-us"
                              href={`https://sidr.com${pageProps.seoDetails.url.includes("?") ? pageProps.seoDetails.url.split('?')[0] : pageProps.seoDetails.url}`}></link>
                    </> :
                    <>
                        <meta property="og:url" content="https://sidr.com"/>
                        <link rel="canonical" href="https://sidr.com" key="canonical" hrefLang={'en-us'}></link>
                    </>
            }
            <meta name="viewport"
                  content="user-scalable=yes, initial-scale=1, maximum-scale=5, minimum-scale=1, width=device-width"/>

            {/* eslint-disable-next-line @next/next/no-sync-scripts */}
            <script type="text/javascript" src="/assets/script/google-tag-manager.js" async></script>
        </Head>

        {/*Google tag (gtag.js)*/}
        <link rel="preconnect" href="https://www.googletagmanager.com"></link>
        <Script async src="https://www.googletagmanager.com/gtag/js?id=G-EFD3YDN0FR"
                strategy="afterInteractive"></Script>

        <Script id="google-analytics" strategy="afterInteractive">
            {`
                               window.dataLayer = window.dataLayer || [];
                               function gtag(){dataLayer.push(arguments);}
                               gtag('js', new Date());
                               gtag('config', 'G-EFD3YDN0FR');
                           `}
        </Script>

        {/*Google Tag Manager (noscript)*/}
        <noscript>
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KVJSFB6C" height="0" width="0"
                    className={'hide-google-tag-no-script'}>
            </iframe>
        </noscript>
        {/*End Google Tag Manager (noscript)*/}
        {/* Announcement */}
        {
            navigationProps && navigationProps.announcement && navigationProps.announcement.length > 0 ?
                <>
                    <Announcement announcementList={navigationProps.announcement}></Announcement>
                    <div className={'header-space'}></div>
                </> : <></>
        }
        {/* Top Header */}
        <Header Collections={navigationProps.products}
                isHasAnnouncement={navigationProps.announcement.length > 0}></Header>
        <Component {...pageProps} />
        {/* Footer Detail */}
        <Footer></Footer>
        <CheckoutDialog></CheckoutDialog>
    </>)
}

MyApp.getInitialProps = async (ctx: any) => {

    if (navigationPropsCache) {
        return {navigationProps: navigationPropsCache}
    }
    const responseCollection = await strapi.getCollectionsWithProductsList();
    let announcement = []
    let dynamicSEO: any = {};
    try {
        const responseAnnouncement = await strapi.getAnnouncement();
        if (responseAnnouncement.status == 200 && responseAnnouncement.data && responseAnnouncement.data.data) {
            announcement = responseAnnouncement.data.data
        }
    } catch (e) {
    }
    try {
        const responseSEO = await strapi.getSeoDetails();
        if (responseSEO.status == 200 && responseSEO.data && responseSEO.data.data) {
            dynamicSEO = responseSEO.data.data.attributes;
        }
    } catch (e) {
        console.log(e)
    }

    try {

        let path = ctx.ctx.asPath;
        path = path.substring(1);
        let key = path.replaceAll("/", "_").replaceAll("-", "_");
        if (key == '') {
            key = 'index';
        }
        if (dynamicSEO[key] != null) {
            dynamicSEO = {
                Title: dynamicSEO[key].Title,
                Description: dynamicSEO[key].Description,
                Keyword: dynamicSEO[key].Keyword
            };
        } else {
            dynamicSEO = {
                Title: dynamicSEO['index'].Title,
                Description: dynamicSEO['index'].Description,
                Keyword: dynamicSEO['index'].Keyword
            };
        }
    } catch (e) {

    }

    if (responseCollection.status == 200 && responseCollection.data && responseCollection.data.data) {
        for (let i = 0; i < responseCollection.data.data.length; i++) {
            for (let j = 0; j < responseCollection.data.data[i].products.length; j++) {
                delete responseCollection.data.data[i].products[j].SEO_Image;
                delete responseCollection.data.data[i].products[j].createdBy;
                delete responseCollection.data.data[i].products[j].updatedBy;
            }
        }
        return {
            navigationProps: {
                products: responseCollection.data.data,
                announcement,
                dynamicSEO,
            },
        }
    }
}
export default MyApp;
