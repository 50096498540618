import {combineReducers, configureStore} from '@reduxjs/toolkit';
import storage from './storage';
import {persistReducer} from 'redux-persist';
import {cartReducer} from './cart/cart.slice';
import {carrOpenCloseReducer} from './cart/cart-open.slice';
import {persistStore} from 'redux-persist'

const reducer = {sidrCart: cartReducer, cartOpenClose : carrOpenCloseReducer};
const reducers = combineReducers(reducer);
const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['cartOpenClose']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const sidrStore = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});
const persistor = persistStore(sidrStore);

export {sidrStore, persistor};
