import axios from 'axios';
import process from "process";

/**
 *  created request object of axios
 */
const requestInstance = axios.create({baseURL: `${process.env.STRAPIADMINURL}api`, timeout: 15000,});

requestInstance.defaults.headers.common['Authorization'] = `bearer ${process.env.STRAPITOKEN}`
/**
 * Define the standard methods of the network request to reuse at all places.
 */
export const request = {
    /**
     * Stander post method
     * @param url
     * @returns {Promise<*>}
     */
    get: (url: string) => requestInstance.get(url),
    put: (url: string, data: any) => requestInstance.put(url, data),
    post: (url: string, data: any) => requestInstance.post(url, data),
};


export const strapi = {
    getPolicy: () => request.get('privacy-policy'),
    getTermsOfUse: () => request.get('terms-of-use'),
    getCookiePolicy: () => request.get('cookie-policy'),
    getReturnPolicy: () => request.get('return-policy'),
    getShippingPolicy: () => request.get('shipping-policy'),
    getSupplierCodeOfConduct: () => request.get('supplier-code-of-conduct'),
    getAboutUs: () => request.get('abouts?populate=*'),
    getPDF: () => request.get('sidr-pdf?populate=*'),
    getSeoDetails: () => request.get('seo?populate=*'),
    getBannerList: () => request.get('banners-list?populate=*'),
    getCollectionsWithProductsList: () => request.get('collections-list/withProducts'),
    getAnnouncement: () => request.get('announcements'),
    getCollection: (link: string) => request.get(`collections-list?filters[Link][$eqi]=${link}`),
    getProductsList: (query: string = '') => request.get(`products-list?populate=*&${query}`),
    getImpactList: () => request.get('impacts?populate=*'),
    getInnovationList: () => request.get('innovations?populate=*'),
    getCareList: (query: string = '') => request.get(`boards-list?${query}`),
    getFaqList: (query: string) => request.get(`faqs-list?populate=*&${query}`),
    getUpdateFaqView: (id: string, data: any) => request.put(`faqs-list/${id}`, data),
    getMediaList: (pagination: string) => request.get(`media?populate=*&${pagination}`),
    getRecipeList: (query: string) => request.get(`recipes?populate=*&${query}`),
    getJobList: (query: string) => request.get(`careers?populate=*&${query}`),
    getJob: (id: string) => request.get(`careers/${id}`),
    getMedia: (link: string) => request.get(`media?populate=*&filters[Link][$eqi]=${link}`),
    updateMediaView: (id: string, data: any) => request.put(`media/${id}`, data),
    updateRecipesView: (id: string, data: any) => request.put(`recipes/${id}`, data),
    updateProductView: (id: string, data: any) => request.put(`products-list/${id}`, data),
    applyForJob: (data: any) => request.post(`career-application`, data),
    upload: (data: any) => request.post(`upload`, data),
};
