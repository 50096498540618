import * as React from "react";
import style from '@styles/layouts/footer/footer.module.scss';
import {useState} from "react";
import {subscription} from "@api/subscription";

export const Footer: React.FC = () => {

    // Hold the current year value, so no need to update the value every year
    // const currentYear = new Date().getFullYear();

    /**
     * subscribeFormState variable is used to manage the current state of the subscription based on value the view is appear to the user
     * 0 represent show the subscribe form
     * 1 represent successfully submitted
     * 2 mean already subscribed
     */
    const [subscribeFormState, setSubscribeFormState] = useState(0)

    /**
     * isChecked is used to store the check mark value for age restriction
     */
    const [isChecked, setIsChecked] = useState(false);

    /**
     * validations are used to handle the error message on view
     * isEmailValid is used for email validation
     * isChecked is used for age restriction checkmark
     */
    const [validations, setValidation] = useState({isEmailValid: true, isChecked: true})

    /**
     * email is used for store the value of the email input.
     */
    const [email, setEmail] = useState('');

    /**
     * isLoading is used for api call, using this we can show user that the data was submitted and processing.
     */
    const [isLoading, setIsLoading] = useState(false);

    /**
     * CLick action of the stay tune button
     * We are calling rest api over here and based on status code we are showing the message
     */
    const clickSubscribeEmail = () => {
        if (email == '' && !isChecked) {
            setValidation({isEmailValid: false, isChecked: false})
        } else if (email == '') {
            setValidation({isChecked: true, isEmailValid: false})
        } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            setValidation({isChecked: true, isEmailValid: false})
        } else if (!isChecked) {
            setValidation({isEmailValid: true, isChecked: false})
        } else {
            setValidation({isEmailValid: true, isChecked: true})
            setIsLoading(true)
            subscription.subscribeEmail(email)
                .then((response) => {
                    setIsLoading(false)
                    if (response.status == 200) {
                        setSubscribeFormState(2)
                    } else if (response.status == 201) {
                        setSubscribeFormState(1)
                    }
                    setEmail('')
                    setIsChecked(false)
                }).catch(error => {
                setIsLoading(false)
            })
        }
    }

    /**
     * this section contain the html code for the footer section.
     * in this contain the subscribe form also, which is used for new latter subscription.
     * it also contains the navigation links for the pages of privacy policy, terms and use, cookies policy
     * shipping policy, supplier code of conduct and copy right text.
     */
    return (
        <>
            <footer className={style.footer}>
                <div className="info_subscribe_container">
                    <div className="footer_detail">
                        <a href={'/'}><img src="/assets/images/decorations/extra/sidr_logo_white.svg" height={28} width={147} loading={'lazy'} alt="sidr"/></a>
                        <span className={'welcome'}>welcome to our living laboratory -</span>
                        <span className={'detail'}>the sidr honey is the difference</span>
                    </div>
                    <div className={'subscribe'}>
                        <span className={'special-offer-text'}>Let’s keep the conversation going</span>
                        <form className={`${subscribeFormState != 0 ? 'hide' : ''}`}>
                            <div className={'form-container'}>
                                <input type={"email"} placeholder={'Your E-mail'} onChange={e => {
                                    setEmail(e.currentTarget.value);
                                }} aria-label={'Email'} />
                                <button type={"button"} className={`${isLoading ? 'hide' : ''}`}
                                        onClick={clickSubscribeEmail}>stay tuned
                                </button>
                                <div className={`loading ${isLoading ? 'show' : ''}`}>
                                    <div data-title="dot-falling">
                                        <div className="stage">
                                            <div className="dot-falling"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className={`error-message ${validations.isEmailValid ? '' : 'show'}`}>Please enter valid
                                email!</p>
                            <span className={'agree-checkbox'}>
                                <input id={'checkbox'} className={`${validations.isChecked ? '' : 'checkbox-error'}`}
                                       type="checkbox" name="checkbox" checked={isChecked} aria-label={'Checkbox'}
                                       onChange={() => setIsChecked((prev) => !prev)} required={true}/>
                                <label htmlFor={'checkbox'} onClick={() => {
                                    setIsChecked(!isChecked)
                                }}>By checking the box, you agree that you are at least <br/>18 years of age.</label>
                            </span>
                        </form>
                        <p className={`subscribed-messages ${subscribeFormState == 1 ? 'show' : ''}`}>Thanks for signing
                            up!</p>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <p className={`subscribed-messages ${subscribeFormState == 2 ? 'show' : ''}`}>You're already
                            subscribed to our list.</p>
                    </div>
                </div>
                <div className={'other_links'}>
                    <a href={'/be-part-of-sidr'}>be part of sidr</a>
                    <a href={'/consumer-care'}>consumer care</a>
                    <a href={'/media'}>media</a>
                </div>
                <div className={'nav_links'}>
                    <ul>
                        <li><a href={'/privacy-policy'}>Privacy Policy</a></li><li>•</li>
                        <li><a href={'/terms-of-use'}>Terms of Use</a></li><li>•</li>
                        <li><a href={'/cookie-policy'}>Cookie Policy</a></li><li>•</li>
                        <li><a href={'/return-policy'}>Return Policy</a></li><li>•</li>
                        <li><a href={'/shipping-policy'}>Shipping Policy</a></li><li>•</li>
                        <li><a href={'/supplier-code-of-conduct'}>Supplier Code of Conduct</a></li><li>•</li>
                        <li>© 2023 SIDR</li>
                    </ul>
                </div>
            </footer>
        </>
    )
}
