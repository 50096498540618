import axios from 'axios';

/**
 *  created request object of axios
 */
const requestInstance = axios.create({baseURL: '/api/', timeout: 15000,});
/**
 * Define the standard methods of the network request to reuse at all places.
 */
export const request = {
    /**
     * Stander post method
     * @param url
     * @param body
     * @returns {Promise<*>}
     */
    post: (url: string, body: any) => requestInstance.post(url, body),
};


export const subscription = {

    /**
     * Subscribe to new email
     * @param email
     * @returns {Promise<*>}
     */
    subscribeEmail: (email: string) => request.post('subscribe', {email}),
    applyForJob: (formData: any) => request.post('job-application', formData)
};
